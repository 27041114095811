import React from 'react';
import './Locate.css'

function Locate() {
  return <div className='locate-container' id='LocateUs'>
    <div className="container-title">
      <h2 className="title-primary">Locate</h2>
      <h3 className="title-secondary">Us</h3>
    </div>
    <div className="locate-container-inner">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484038.109297439!2d73.789645!3d18.594418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6aa5e2f728668be6!2sAptech%20Learning%20and%20Aviation%20Academy%2C%20Pimple%20Saudagar%2C%20PCMC%20Pune!5e0!3m2!1sen!2sus!4v1644564194394!5m2!1sen!2sus" width="100%" height="450" style={{border: "0", margin: "auto"}} allowFullScreen={true} loading="lazy"></iframe>
    </div>
  </div>;
}

export default Locate;
