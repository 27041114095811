import React, { useState } from "react";
// import { Parallax } from 'react-parallax';
import "./Header.css";

function Header({ showContact, setShowContact }) {
  const [yOffset, setOffset] = useState(0);
  // const [contHeight , setheight] = useState(0);
  window.onscroll = () => {
    setOffset(window.pageYOffset);
    // console.log(yOffset/150);
  };

  return (
    <div className="header-container">
      <div
        className="header-bg"
        style={{ backgroundPositionY: `${yOffset / 100}px` }}
      >
        <div className="header-content">
          <div className="header-content-inner">
            <h1
              className="main-header tracking-in-expand"
              style={{ letterSpacing: yOffset / 150 + "vw" }}
            >
              EDULADE
            </h1>
            <div
              className="header-text"
              style={{ transform: `translateY(${yOffset / 10}px)` }}
            >
              <h2 className="header-text-sub">We provide</h2>
              <h3>
                next generation{" "}
                <p>
                  <a href="/#services">Transformations</a>{" "}
                </p>
              </h3>
              <h3>and</h3>
              <h3>
                consulting{" "}
                <p>
                  <a href="/#services">Solutions</a>
                </p>
              </h3>
              <a
                className="cta-button"
                type="button"
                href={"#"}
                onClick={(e) => {
                  setShowContact(true);
                  e.preventDefault();
                }}
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
        <div className="scroll-down">
          <a href="/#services">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="51.752"
              height="84.986"
              viewBox="0 0 51.752 84.986"
            >
              <g id="scroll-down-icon" transform="translate(-172.285 -4560.43)">
                <path
                  id="Path_404"
                  data-name="Path 404"
                  d="M174.285,4582.678c.068-13.962,6.67-20.226,20.245-20.245h5.942c12.814-.15,21.616,5.267,21.566,20.245v39.831c-.118,16.625-6.043,20.688-20.947,20.787,0,0-.3.139-6.56.118h0c-13.235.121-20.006-5.465-20.243-20.905Z"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="4"
                />
                <path
                  id="Path_405"
                  data-name="Path 405"
                  d="M198.161,4585.625v-8.783"
                  transform="translate(0 3.347)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="6"
                />
              </g>
            </svg>
            <p>scroll down</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
