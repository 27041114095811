import React, { useState } from "react";
import "./ContactUs.css";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function ContactUs({ showContact, setShowContact }) {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setContact({ ...contact, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1jq405f",
        "template_ffl4o6j",
        e.target,
        "bsZgrLW1IqM42Tt9l"
      )
      .then(
        (result) => {
          alert("Sent Successfully");
        },
        (error) => {
          alert("Failed to Send!!!");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className={`model-bg ${showContact ? "shown" : ""}`}></div>
      <div className={`contact-us-container  ${showContact ? "shown" : ""}`}>
        <span
          className="contact-us-close"
          onClick={() => setShowContact(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className={`contact-us-container-inner`}>
          <h1 className="title-primary">Contact Us</h1>
          <form className={`contact-us-form  `} onSubmit={sendEmail}>
            <div className="contact-us-inputs">
              <input
                type="name"
                name="name"
                id="name"
                placeholder="Your Name"
                onChange={handleInputs}
                required
              />
              <label htmlFor="name">Your Name</label>
            </div>
            <div className="contact-us-inputs">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                onChange={handleInputs}
                required
              />
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="contact-us-inputs">
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                onChange={handleInputs}
              />
              <label htmlFor="subject">Subject</label>
            </div>
            <div className="contact-us-inputs">
              <textarea
                name="message"
                id="message"
                placeholder="Message"
                rows={5}
                onChange={handleInputs}
                required
              />
              <label htmlFor="message">Message</label>
            </div>
            <input type="submit" className="cta-button" />
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
