import React, { useEffect, useRef, useState } from "react";
import "./ServicesPg.css";
import { useLocation } from "react-router-dom";
import ServicesJSON from "./Services.json";
import Header from "../../Components/header/Header";
import NotFound from "../NotFound/NotFound";
import servImg1 from "../../assets/img/servImg1.png";
import servImg2 from "../../assets/img/servImg2.svg";
import servImg3 from "../../assets/img/servImg3.svg";
import servImg4 from "../../assets/img/servImg4.svg";
import servImg5 from "../../assets/img/servImg5.svg";
import servImg6 from "../../assets/img/servImg6.svg";

function ServicesPg() {
  const location = useLocation();
  const serviceUrl = location.pathname.split("/").pop();
  const [services, setServices] = useState([]);

  const servicesImgs = [
    servImg1,
    servImg2,
    servImg3,
    servImg4,
    servImg5,
    servImg6,
  ];

  const servicesLocations = [
    "Web-Software-Development",
    "Android-App-Development",
    "IT-Consulting",
    "Skill-Development",
    "Internship-programs",
    "Entrepreneurship-Development",
  ];

  useEffect(() => {
    document.title = `Edulade | ${serviceUrl}`;
    setServices(JSON.parse(JSON.stringify(ServicesJSON)));
  }, []);

  const createServiceDetails = (currS) => {
    console.log(currS.details);
    return {
      __html: currS.details,
    };
  };

  const createService = () => {
    var currService;
    if (serviceUrl.includes(serviceUrl)) {
      const serviceIndex = servicesLocations.indexOf(serviceUrl);
      if (services[serviceIndex]) {
        currService = services[serviceIndex];
      } else {
        return <NotFound />;
      }

      return (
        <div className="services-container-inner">
          <img
            className="service-img"
            src={servicesImgs[serviceIndex]}
            alt="Service Img"
          />
          <h2 className="service-title">{currService.title}</h2>
          {!currService.details ? (
            <p className="service-discription">{currService.discription}</p>
          ) : (
            <div
              className="service-details"
              style={{ textAlign: "left", fontWeight: 300 }}
              dangerouslySetInnerHTML={createServiceDetails(currService)}
            ></div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="services-container">
      <Header />
      {createService()}
    </div>
  );
}

export default ServicesPg;
