import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container" id="AboutUs">
      <div className="container-title">
        <h2 className="title-primary">About</h2>
        <h3 className="title-secondary">Us</h3>
      </div>
      <div className="about-container-inner">
        <p>
          Edulade Technologies, having HQ in Pune, India is a IT development and
          consulting organization having Business Partnership with Aptech
          Learning and Aptech Aviation Academy. Edulade has three LOBs
          comprising of Software Development & Consulting, second LOB is Career
          and Professional training in IT to cater to generic and niche skill
          demands of IT industry and third LOB is to provide Professional and
          Job oriented certifications and trainings needed for Aviation,
          Hospitality, Retail and Tourism industry. Aptech is a Global Learning
          Solutions company that commenced its education and training business
          in 1986. It has presence in 40 countries and has trained over 7
          million students globally via 1300 centers worldwide.
        </p>
        {/* <a href="mailto:info@edulade.com" className="about-btn cta-button">
          Know More
        </a> */}
      </div>
    </div>
  );
}

export default About;
