import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./ScrollTop.css";

function ScrollTop() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.scrollingElement.scrollTop > window.innerHeight * 2) {
        setScrolled(true);
      } else setScrolled(false);
    });

    return window.removeEventListener("scroll", () => {
      return;
    });
  }, [document.scrollingElement.scrollTop]);
  return (
    <div className="scroll-top-container ">
      <a
        href="#"
        className={`scroll-top-container-inner ${scrolled ? "active" : ""}`}
      >
        <p>
          <FontAwesomeIcon icon={faAngleUp} />
        </p>
      </a>
    </div>
  );
}

export default ScrollTop;
