import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Navbar from "./Components/navbar/Navbar";
import Footer from "./Components/footer/Footer";
import HiringPG from "./container/Hirings/HiringPG";
import NotFound from "./container/NotFound/NotFound";
import ScrollTop from "./Components/ScrpllTop/ScrollTop";
import ContactUs from "./Components/ContactUs/ContactUs";
import CaseStudies from "./container/CaseStudies/CaseStudies";
import CaseStudiesAdmin from "./container/Admin/CaseStudiesAdmin/CaseStudiesAdmin";
import HiringPGAdmin from "./container/Admin/HiringPGAdmin/HiringPGAdmin";
import Admin from "./container/Admin/Admin";
import ServicesPg from "./container/SerivcePg/ServicesPg";

function App() {
  const [showContact, setShowContact] = useState(false);
  return (
    <>
      <Router>
        <Navbar showContact={showContact} setShowContact={setShowContact} />
        <ContactUs showContact={showContact} setShowContact={setShowContact} />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Home showContact={showContact} setShowContact={setShowContact} />
            }
          ></Route>
          <Route path="/Hirings" element={<HiringPG />} />
          {/* <Route path="/HiringsCopy" element={<HiringPGCopy />} /> */}
          <Route path="/CaseStudies" element={<CaseStudies />} />
          <Route path="/Admin" exact element={<Admin />} />
          <Route path="/Admin/CaseStudies" element={<CaseStudiesAdmin />} />
          <Route path="/Admin/Hirings" element={<HiringPGAdmin />} />
          <Route path="/Services/*" element={<ServicesPg />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <ScrollTop />
        <Footer />
      </Router>
    </>
  );
}

export default App;
