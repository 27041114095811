import React from "react";
import spinner from "../../assets/svg/spinner.svg";
import "./LoadingPage.css";

const LoadingPage = () => {
  return (
    <div className="loader">
      <img src={spinner} alt="spinner" />
      <h2>Loading...</h2>
    </div>
  );
};

export default LoadingPage;
