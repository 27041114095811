import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./CaseStudies.css";
import Header from "../../Components/header/Header";
import NoPreview from "../../assets/img/NoPreview.png";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";

function CaseStudies() {
  const [caseStudiesData, setCaseStudiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCaseStudyPopup, setshowCaseStudyPopup] = useState(false);
  const [popUpContent, setpopUpContent] = useState({
    title: "",
    clientName: "",
    link: "",
    details: "",
    previewImg: "",
  });
  // const apiUrl = "https://eduladecombackend.vercel.app";
  const apiUrl = process.env.APIURL || "https://eduladecombackend.vercel.app";

  useEffect(() => {
    getCaseStudies();
  }, []);

  const getCaseStudies = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${apiUrl}/GetCaseStudies`);
      const data = await res.json();
      if (data.error) {
        setLoading(false);
        return;
      }
      setCaseStudiesData(data.reverse());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const createCaseStudies = () => {
    if (caseStudiesData.length === 0 && loading === false) {
      return (
        <div className="no-case-studies-data">
          Error getting Case Studies :( <br /> Please check back later.
        </div>
      );
    }
    return caseStudiesData.map((study, i) => {
      return (
        <div
          className="case-studies-item"
          key={i}
          onClick={() => {
            handleCaseStudiesPopup(study);
          }}
        >
          <img
            src={
              study.previewImg
                ? `data:${study.previewImg.type};base64, ${study.previewImg.data}`
                : NoPreview
            }
            alt="Case Study img"
          />
          <div className="case-studies-content">
            <h2>{study.title}</h2>
            <p>click for more info</p>
          </div>
        </div>
      );
    });
  };

  const handleCaseStudiesPopup = (study) => {
    let { title, clientName, link, details, previewImg } = study;
    setpopUpContent({
      ...popUpContent,
      title,
      clientName,
      link,
      details,
      previewImg,
    });
    setshowCaseStudyPopup(true);
  };
  function createDetails(detail) {
    if (detail) {
      return { __html: detail };
    } else {
      return {
        __html: "Not Provided",
      };
    }
  }
  const CaseStudyPopup = () => {
    return (
      <div
        className={`case-study-popup-container ${
          showCaseStudyPopup ? "active" : ""
        }`}
      >
        <div className="case-study-popup-container-inner">
          <span
            className="case-study-popup-close"
            onClick={() => {
              setshowCaseStudyPopup(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <div className="case-study-popup-content">
            <h2 style={{ color: "var(--color-primary)" }}>
              {popUpContent.title ? popUpContent.title : "Case Study"}
            </h2>
          </div>
          <div className="case-study-popup-content">
            <h3>Client Name: </h3>
            <p>
              {popUpContent.clientName
                ? popUpContent.clientName
                : "Not Provided"}
            </p>
          </div>
          <div className="case-study-popup-content">
            <h3>Project Link: </h3>
            <p>
              {popUpContent.link ? (
                <a
                  href={popUpContent.link}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {popUpContent.link}
                </a>
              ) : (
                "Not Provided"
              )}
            </p>
          </div>
          <div className="case-study-popup-content">
            <h3>About Project: </h3>
            <br />
            <br />
            {popUpContent.details ? (
              <p
                dangerouslySetInnerHTML={createDetails(popUpContent.details)}
              ></p>
            ) : (
              "Not Provided"
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <CaseStudyPopup />
      <div className="case-studies-container">
        <div className="container-title">
          <h2 className="title-primary">Case Studies</h2>
          {/* <h3 className="title-secondary">in Edulade</h3> */}
        </div>
        <div className="case-studies-container-inner">
          {loading ? <LoadingPage /> : ""}
          {createCaseStudies()}
        </div>
      </div>
    </>
  );
}

export default CaseStudies;
